<template>
    <!-- <Layout> -->
    <div class="px-1 mt-1">
        <PageHeader :title="title" backPath="/players" />
        <div class="row">
            <div class="col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body pt-5">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <img
                                        height="24"
                                        src="@/assets/images/profile-default.png"
                                        alt
                                        class="img-thumbnail rounded-circle" />
                                </div>
                                <h5
                                    class="font-size-15 text-truncate text-primary">
                                    {{ username }}
                                </h5>
                                <p class="text-muted mb-0 text-truncate">
                                    {{ $t(`player`) }}
                                </p>
                            </div>
                            <div class="col-sm-8">
                                <div class="">
                                    <div class="row">
                                        <div class="col-7">
                                            <h5 class="font-size-15">
                                                2022-09-07
                                            </h5>
                                            <p class="text-muted mb-0">
                                                {{ $t("last logged in at") }}
                                            </p>
                                        </div>
                                        <div class="col-5">
                                            <h5 class="font-size-15 fw-bold">
                                                192.168.1.0
                                            </h5>
                                            <p class="text-muted mb-0">
                                                {{ $t("last logged in IP") }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="mt-4 button-items">
                                        <a
                                            href
                                            class="btn btn-info btn-sm"
                                            @click.prevent="
                                                message_modal = true;
                                                edit_mode = false;
                                            ">
                                            <i class="mdi mdi-email-send"></i>
                                            {{ $t("message") }}
                                        </a>
                                        <a
                                            href
                                            class="btn btn-danger btn-sm"
                                            @click.prevent="
                                                forceLogout();
                                                edit_mode = false;
                                            ">
                                            <i class="mdi mdi-logout"></i>
                                            {{ $t("force logout") }}
                                        </a>
                                        <b-modal
                                            v-model="message_modal"
                                            @show="focusMessage()"
                                            @hidden="reset()"
                                            centered
                                            :title="$t('new message')"
                                            title-class="font-18"
                                            hide-footer>
                                            <b-form
                                                @submit.prevent="sendMessage">
                                                <b-form-group
                                                    class="mb-3"
                                                    :label="$t('to')"
                                                    label-cols-sm="2">
                                                    <input
                                                        readonly
                                                        :value="username"
                                                        type="text"
                                                        class="form-control" />
                                                </b-form-group>
                                                <b-form-group
                                                    class="mb-3"
                                                    :label="$t('subject')"
                                                    label-cols-sm="2">
                                                    <input
                                                        ref="subject"
                                                        v-model="subject"
                                                        :placeholder="
                                                            $t('enter subject')
                                                        "
                                                        type="text"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.subject
                                                                    .$error,
                                                        }" />

                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.subject.$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="item.$message"
                                                            >{{
                                                                item.$message
                                                            }}</span
                                                        >
                                                    </div>
                                                </b-form-group>
                                                <b-form-group
                                                    class="mb-3"
                                                    label-class="text-danger fw-bold">
                                                    <textarea
                                                        v-model="message"
                                                        :placeholder="`${$t(
                                                            'enter your message here'
                                                        )}...`"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid':
                                                                submitted &&
                                                                v$.message
                                                                    .$error,
                                                        }"
                                                        cols="30"
                                                        rows="10"
                                                        style="
                                                            resize: none;
                                                        "></textarea>
                                                    <div
                                                        v-for="(
                                                            item, index
                                                        ) in v$.message.$errors"
                                                        :key="index"
                                                        class="invalid-feedback">
                                                        <span
                                                            v-if="item.$message"
                                                            >{{
                                                                item.$message
                                                            }}</span
                                                        >
                                                    </div>
                                                </b-form-group>
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary w-100 float-end">
                                                    <i class="bx bxs-send"></i>
                                                    {{ $t("send") }}
                                                </button>
                                            </b-form>
                                        </b-modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <h4 class="card-title mb-4">
                                    {{ $t("account information") }}
                                    <a
                                        v-if="!edit_mode"
                                        class="btn btn-link float-end"
                                        @click.prevent="edit_mode = true">
                                        <i class="mdi mdi-account-edit"></i>
                                        {{ $t(`edit`) }}
                                    </a>
                                    <a
                                        v-else
                                        class="btn btn-link float-end text-danger"
                                        @click.prevent="
                                            edit_mode = false;
                                            reset();
                                        ">
                                        <i class="mdi mdi-close-thick"></i>
                                        {{ $t(`cancel`) }}
                                    </a>
                                </h4>
                                <b-form @submit.prevent="updateUser">
                                    <div class="table-responsive">
                                        <table class="table table-nowrap mb-0">
                                            <tbody>
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        class="text-end">
                                                        {{ $t("name") }} :
                                                    </th>
                                                    <td>
                                                        <div
                                                            class="row"
                                                            v-if="edit_mode">
                                                            <div class="col-6">
                                                                <b-input-group>
                                                                    <input
                                                                        v-model="
                                                                            first_name
                                                                        "
                                                                        type="text"
                                                                        class="form-control rounded"
                                                                        :class="{
                                                                            'is-invalid':
                                                                                submitted &&
                                                                                v$
                                                                                    .first_name
                                                                                    .$error,
                                                                        }"
                                                                        :placeholder="
                                                                            $t(
                                                                                `first name`
                                                                            )
                                                                        " />
                                                                    <div
                                                                        v-for="(
                                                                            item,
                                                                            index
                                                                        ) in v$
                                                                            .first_name
                                                                            .$errors"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        class="invalid-feedback">
                                                                        <span
                                                                            v-if="
                                                                                item.$message
                                                                            "
                                                                            >{{
                                                                                item.$message
                                                                            }}</span
                                                                        >
                                                                    </div>
                                                                </b-input-group>
                                                            </div>
                                                            <div class="col-6">
                                                                <b-input-group>
                                                                    <input
                                                                        v-model="
                                                                            last_name
                                                                        "
                                                                        type="text"
                                                                        class="form-control rounded"
                                                                        :class="{
                                                                            'is-invalid':
                                                                                submitted &&
                                                                                v$
                                                                                    .last_name
                                                                                    .$error,
                                                                        }"
                                                                        :placeholder="
                                                                            $t(
                                                                                `last name`
                                                                            )
                                                                        " />
                                                                    <div
                                                                        v-for="(
                                                                            item,
                                                                            index
                                                                        ) in v$
                                                                            .last_name
                                                                            .$errors"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        class="invalid-feedback">
                                                                        <span
                                                                            v-if="
                                                                                item.$message
                                                                            "
                                                                            >{{
                                                                                item.$message
                                                                            }}</span
                                                                        >
                                                                    </div>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            {{
                                                                first_name +
                                                                " " +
                                                                last_name
                                                            }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        class="text-end">
                                                        {{ $t("contact no") }} :
                                                    </th>
                                                    <td>
                                                        <div v-if="edit_mode">
                                                            <b-input-group>
                                                                <input
                                                                    v-model="
                                                                        contact_no
                                                                    "
                                                                    type="text"
                                                                    class="form-control rounded"
                                                                    :class="{
                                                                        'is-invalid':
                                                                            submitted &&
                                                                            v$
                                                                                .contact_no
                                                                                .$error,
                                                                    }"
                                                                    :placeholder="
                                                                        $t(
                                                                            `phone number`
                                                                        )
                                                                    " />
                                                                <div
                                                                    v-for="(
                                                                        item,
                                                                        index
                                                                    ) in v$
                                                                        .contact_no
                                                                        .$errors"
                                                                    :key="index"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        v-if="
                                                                            item.$message
                                                                        "
                                                                        >{{
                                                                            item.$message
                                                                        }}</span
                                                                    >
                                                                </div>
                                                            </b-input-group>
                                                        </div>
                                                        <div v-else>
                                                            {{ contact_no }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        class="text-end">
                                                        {{
                                                            $t("email address")
                                                        }}
                                                        :
                                                    </th>
                                                    <td>
                                                        <div v-if="edit_mode">
                                                            <b-input-group>
                                                                <input
                                                                    v-model="
                                                                        email_address
                                                                    "
                                                                    type="text"
                                                                    class="form-control rounded"
                                                                    :class="{
                                                                        'is-invalid':
                                                                            submitted &&
                                                                            v$
                                                                                .email_address
                                                                                .$error,
                                                                    }"
                                                                    :placeholder="
                                                                        $t(
                                                                            `email address`
                                                                        )
                                                                    " />
                                                                <div
                                                                    v-for="(
                                                                        item,
                                                                        index
                                                                    ) in v$
                                                                        .email_address
                                                                        .$errors"
                                                                    :key="index"
                                                                    class="invalid-feedback">
                                                                    <span
                                                                        v-if="
                                                                            item.$message
                                                                        "
                                                                        >{{
                                                                            item.$message
                                                                        }}</span
                                                                    >
                                                                </div>
                                                            </b-input-group>
                                                        </div>
                                                        <div v-else>
                                                            {{ email_address }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-center" v-if="edit_mode">
                                        <button
                                            type="submit"
                                            class="btn btn-warning w-100 mt-3">
                                            {{ $t("save") }}
                                        </button>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header bg-light bg-soft filters">
                        <h4 class="card-title my-3">
                            {{ $t("transactions") }}
                        </h4>
                        <b-form inline class="row gy-2 gx-3 align-items-center">
                            <div class="col-sm-4">
                                <label>{{ $t("from") }}</label>
                                <input
                                    type="date"
                                    class="form-control form-control-sm" />
                            </div>
                            <div class="col-sm-auto">
                                <label>{{ $t("to") }}</label>
                                <input
                                    type="date"
                                    class="form-control form-control-sm" />
                            </div>
                            <div class="col-sm-auto">
                                <label>Type </label>
                                <select class="form-control form-control-sm">
                                    <option value="" selected>
                                        {{ $t("all") }}
                                    </option>
                                    <option value="deposit">
                                        {{ $t("deposit") }}
                                    </option>
                                    <option value="withdraw">
                                        {{ $t("withdraw") }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-auto">
                                <button class="btn btn-light btn-sm mt-4">
                                    {{ $t("search") }}
                                </button>
                            </div>
                        </b-form>
                    </div>
                    <div class="card-body pb-1">
                        <div class="table-responsive" style="max-height: 20vh">
                            <table
                                class="table table-bordered table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            {{ $t("type") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("amount") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("date/time") }}
                                        </th>
                                        <th scope="col">
                                            {{ $t("status") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="r in transaction_data.list"
                                        :key="r.id">
                                        <td class="text-center">
                                            {{
                                                $t(
                                                    `${
                                                        r.type == 1
                                                            ? "deposit"
                                                            : r.type == 2
                                                            ? "withdraw"
                                                            : ""
                                                    }`
                                                )
                                            }}
                                        </td>
                                        <td class="text-end">
                                            {{ numberString(r.amount) }}
                                        </td>
                                        <td class="text-center">
                                            {{ r.created_at }}
                                        </td>
                                        <td class="text-center fw-bold">
                                            <div
                                                class="text-info"
                                                v-if="r.status == 0">
                                                {{ $t(`pending`) }}
                                            </div>
                                            <div
                                                class="text-success"
                                                v-else-if="r.status == 1">
                                                {{ $t(`approved`) }}
                                            </div>
                                            <div
                                                class="text-danger"
                                                v-else-if="r.status == 2">
                                                {{ $t(`declined`) }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            :data="transaction_data"
                            @emitPage="initBetList" />
                    </div>
                </div>
            </div>

            <div class="col-xl-8">
                <div class="row">
                    <div class="col-md-3">
                        <div class="card mini-stats-wid bg-warning bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("current balance") }}
                                        </p>
                                        <h4 class="mb-0 fw-bold">
                                            {{ numberString(100000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span class="avatar-title bg-warning">
                                            <i
                                                :class="`bx bxs-wallet font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card mini-stats-wid bg-info bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("total deposit amount") }}
                                        </p>
                                        <h4 class="mb-0">
                                            {{ numberString(1000000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span
                                            class="avatar-title bg-dark bg-soft">
                                            <i
                                                :class="`bx bxs-log-in-circle font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card mini-stats-wid bg-info bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("total withdraw amount") }}
                                        </p>
                                        <h4 class="mb-0">
                                            {{ numberString(900000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span
                                            class="avatar-title bg-dark bg-soft">
                                            <i
                                                :class="`bx bxs-log-out-circle font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card mini-stats-wid bg-info bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("total bet amount") }}
                                        </p>
                                        <h4 class="mb-0">
                                            {{ numberString(1000000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span
                                            class="avatar-title bg-dark bg-soft">
                                            <i
                                                :class="`bx bx-sort font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mini-stats-wid bg-success bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("total win amount") }}
                                        </p>
                                        <h4 class="mb-0">
                                            {{ numberString(900000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span class="avatar-title bg-success">
                                            <i
                                                :class="`bx bx-up-arrow-alt font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mini-stats-wid bg-danger bg-soft">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium mb-2">
                                            {{ $t("total lose amount") }}
                                        </p>
                                        <h4 class="mb-0">
                                            {{ numberString(100000) }}
                                        </h4>
                                    </div>

                                    <div
                                        class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                                        <span class="avatar-title bg-danger">
                                            <i
                                                :class="`bx bx-down-arrow-alt font-size-24`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body pb-0">
                        <b-tabs
                            pills
                            justified
                            active-nav-item-class="fw-bold bg-info "
                            nav-wrapper-class="bg-light"
                            content-class="p-3 text-muted">
                            <b-tab active>
                                <template v-slot:title>
                                    {{ $t("poker") }}
                                </template>
                                <div class="mb-3">
                                    <b-form
                                        inline
                                        class="row gy-2 gx-3 align-items-center">
                                        <div class="col-sm-auto">
                                            <label
                                                >{{ $t("search by") }}
                                            </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="round">
                                                    {{ $t("round") }}
                                                </option>
                                                <option value="seat">
                                                    {{ $t("seat") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>&nbsp; </label>
                                            <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Search.." />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("status") }} </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="on-going">
                                                    {{ $t("on-going") }}
                                                </option>
                                                <option value="win">
                                                    {{ $t("win") }}
                                                </option>
                                                <option value="lose">
                                                    {{ $t("lose") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("from") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("to") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>

                                        <div class="col-sm-auto">
                                            <button class="btn btn-light mt-4">
                                                {{ $t("search") }}
                                            </button>
                                        </div>
                                    </b-form>
                                </div>
                                <div
                                    class="table-responsive"
                                    style="max-height: 50vh">
                                    <table
                                        class="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    {{ $t("date/time") }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t("seat") }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t("round") }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t("card") }}
                                                </th>

                                                <th scope="col">
                                                    {{ $t("status") }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t("bet amount") }}
                                                </th>

                                                <th scope="col">
                                                    {{ $t("win amount") }}
                                                </th>

                                                <th scope="col">
                                                    {{ $t("actions") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="r in bet_data.list"
                                                :key="r.id">
                                                <td class="text-center">
                                                    {{ r.created_at }}
                                                </td>
                                                <td class="text-center">
                                                    {{ r.seat_no }}
                                                </td>
                                                <td class="text-center">
                                                    {{ r.round }}
                                                </td>
                                                <td>
                                                    <div class="card-group">
                                                        <img
                                                            v-for="(
                                                                c, i
                                                            ) in r.player_cards"
                                                            :key="i"
                                                            :src="
                                                                require(`@/assets/images/cards/${c}.png`)
                                                            "
                                                            alt="" />
                                                    </div>
                                                </td>

                                                <td class="text-center">
                                                    {{ $t(r.status) }}
                                                </td>
                                                <td class="text-end">
                                                    {{
                                                        numberString(
                                                            r.bet_amount
                                                        )
                                                    }}
                                                </td>

                                                <td class="text-end">
                                                    {{
                                                        numberString(
                                                            r.win_amount
                                                        )
                                                    }}
                                                </td>

                                                <td class="text-center">
                                                    <div class="button-items">
                                                        <b-button
                                                            variant="info"
                                                            size="sm">
                                                            {{ $t("view") }}
                                                        </b-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    :data="bet_data"
                                    @emitPage="initBetList" />
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    {{ $t("baccarat") }}
                                </template>
                                <div class="mb-3">
                                    <b-form
                                        inline
                                        class="row gy-2 gx-3 align-items-center">
                                        <div class="col-sm-auto">
                                            <label
                                                >{{ $t("search by") }}
                                            </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="round">
                                                    {{ $t("round") }}
                                                </option>
                                                <option value="seat">
                                                    {{ $t("seat") }}
                                                </option>
                                                <option value="table">
                                                    {{ $t("table") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>&nbsp; </label>
                                            <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Search.." />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("status") }} </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="on-going">
                                                    {{ $t("on-going") }}
                                                </option>
                                                <option value="win">
                                                    {{ $t("win") }}
                                                </option>
                                                <option value="lose">
                                                    {{ $t("lose") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("from") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("to") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>

                                        <div class="col-sm-auto">
                                            <button class="btn btn-light mt-4">
                                                {{ $t("search") }}
                                            </button>
                                        </div>
                                    </b-form>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                    {{ $t("slots") }}
                                </template>
                                <div class="mb-3">
                                    <b-form
                                        inline
                                        class="row gy-2 gx-3 align-items-center">
                                        <div class="col-sm-auto">
                                            <label
                                                >{{ $t("search by") }}
                                            </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="round">
                                                    {{ $t("round") }}
                                                </option>
                                                <option value="seat">
                                                    {{ $t("seat") }}
                                                </option>
                                                <option value="table">
                                                    {{ $t("table") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>&nbsp; </label>
                                            <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Search.." />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("status") }} </label>
                                            <select class="form-control">
                                                <option value="" selected>
                                                    {{ $t("all") }}
                                                </option>
                                                <option value="on-going">
                                                    {{ $t("on-going") }}
                                                </option>
                                                <option value="win">
                                                    {{ $t("win") }}
                                                </option>
                                                <option value="lose">
                                                    {{ $t("lose") }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("from") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>
                                        <div class="col-sm-auto">
                                            <label>{{ $t("to") }}</label>
                                            <input
                                                type="date"
                                                class="form-control" />
                                        </div>

                                        <div class="col-sm-auto">
                                            <button class="btn btn-light mt-4">
                                                {{ $t("search") }}
                                            </button>
                                        </div>
                                    </b-form>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </Layout> -->
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "../../../components/pagination.vue";

import appConfig from "@/app.config";

import { required, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { mapActions } from "vuex";
import datetime from "../../../mixins/datetime";
import number from "../../../mixins/number";
import Swal from "sweetalert2";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "Player Overview",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, Pagination },
    mixins: [datetime, number],
    data() {
        return {
            title: "Player Overview",
            username: "player001",
            first_name: "Undress",
            last_name: "Bonifacio",
            contact_no: "+63 904 234 9988",
            email_address: "player001@gmail.com",
            transaction_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            bet_data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            edit_mode: false,
            message_modal: false,
            submitted: false,
            subject: "",
            message: "",
        };
    },
    validations: {
        first_name: {
            required: helpers.withMessage("required", required),
        },
        last_name: {
            required: helpers.withMessage("required", required),
        },
        contact_no: {
            required: helpers.withMessage("required", required),
        },
        email_address: {
            required: helpers.withMessage("required", required),
        },
        subject: {
            required: helpers.withMessage("required", required),
        },
        message: {
            required: helpers.withMessage("required", required),
        },
    },
    computed: {
        passwordMatched() {
            return (
                this.new_password != "" &&
                this.message != "" &&
                this.new_password === this.message
            );
        },
        userFormValid() {
            return (
                !this.v$.first_name.$invalid &&
                !this.v$.last_name.$invalid &&
                !this.v$.contact_no.$invalid &&
                !this.v$.email_address.$invalid
            );
        },
        messageFormValid() {
            return !this.v$.subject.$invalid && !this.v$.message.$invalid;
        },
    },
    methods: {
        ...mapActions("transaction", {
            transactionList: "getList",
        }),
        ...mapActions("bet", {
            betList: "getList",
        }),
        async initBetList(p) {
            var pl = {
                page_no: p,
            };
            const data = await this.betList(pl);
            this.bet_data.list = data.data;
            this.bet_data.links = data.links;
            this.bet_data.current_page = data.current_page;
            this.bet_data.per_page = data.per_page;
            this.bet_data.last_page = data.last_page;
        },
        async initTransactionList(p) {
            var pl = {
                page_no: p,
            };
            const data = await this.transactionList(pl);
            this.transaction_data.list = data.data;
            this.transaction_data.links = data.links;
            this.transaction_data.current_page = data.current_page;
            this.transaction_data.per_page = data.per_page;
            this.transaction_data.last_page = data.last_page;
        },
        async updateUser() {
            this.submitted = true;
            this.v$.$touch();
            if (!this.userFormValid) {
                return;
            } else {
                this.edit_mode = false;
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: this.$t("user has been updated"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        },
        async sendMessage() {
            this.submitted = true;
            this.v$.$touch();
            if (!this.messageFormValid) {
                return;
            } else {
                this.message_modal = false;
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: this.$t("message sent"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        },
        async forceLogout() {
            Swal.fire({
                title: `${this.$t("are you sure")}?`,
                text: `${this.$t("logout this user")}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f1b44c",
                cancelButtonColor: "#74788d",
                cancelButtonText: this.$t("cancel"),
                confirmButtonText: this.$t("confirm"),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: this.$t("user has been logged out"),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            });
        },
        focusMessage() {
            setTimeout(() => {
                this.$nextTick(() => this.$refs.subject.focus());
            }, 500);
        },
        reset() {
            this.submitted = false;
            this.subject = "";
            this.message = "";
        },
    },
    mounted() {
        this.initBetList(1);
        this.initTransactionList(1);
    },
};
</script>
